export const Colors = {
  gray1: "#FAFAFA",
  gray2: "#F5F5F5",
  gray3: "#EEEEEE",
  gray4: "#E0E0E0",
  gray5: "#BABABA",
  gray6: "#9E9E9E",
  gray7: "#757575",
  gray8: "#616161",
  gray9: "#424242",
  gray10: "#212121",
  white: "#ffffff",
  black: "#000000",
  heading: "#0e073b",
  bg: "#ffffff",
  accent: "#0156f9",
  landingAccent: "#0156f9",
  link: "#0156f9",
  // hover: "rgba(0,0,0,0.05)",
  hover: `#0156f910`,
  error: "#e74c3c",
  warning: "#ff8800",
  success: "#44bd32",
  landingGray: "#f5f5f5",
  borderColor: "#E0E0E0",
  lightBlue: "#d3e3fd",
  landingFaq: "#f9faff",

  linkColor: "#0156f9",
  title: "#1f243c",
  text: "#1f243c",
  subtitle: "#808182",
};
