import { datadogLogs } from "@datadog/browser-logs";
import * as ServerTypes from "@/api-lib";
import { useUserSlice } from "./useUserSlice";
import Router from "next/router";
import { __DEV__ } from "@/constants";
import { getLocalizedBasePath } from "./utils";

export const cipher = (jsonData: { [key: string]: any }): string => {
  const hexBuff = Buffer.from(JSON.stringify(jsonData));
  const hexData = hexBuff.toString("hex");

  const base64Buff = Buffer.from(hexData);
  return base64Buff.toString("base64");
};

export const getDeviceIdAsync = async () => {
  if (typeof window !== "object") return;

  const FingerprintJS = require("@fingerprintjs/fingerprintjs");
  const fp = await FingerprintJS.load();
  const { visitorId } = await fp.get();

  return `web_${visitorId}`;
};

const useAuthorized = (res: Response): any => {
  if (res.status === 403) {
    Router.replace(`${getLocalizedBasePath()}/`);
    useUserSlice.getState().setUserToken(undefined);

    setTimeout(() => {
      window.location.reload();
    }, 300);

    return res.json();
  }

  return res.json();
};

export const createTranscriptionAsync = async (
  params: ServerTypes.ApiRequestCreateTranscription["payload"]
): Promise<ServerTypes.ApiResponseCreateTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "CreateTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const updateTranscriptionAsync = async (
  params: ServerTypes.ApiRequestUpdateTranscription["payload"]
): Promise<ServerTypes.ApiResponseUpdateTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "UpdateTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const startWebTranscriptionAsync = async (
  params: ServerTypes.ApiRequestStartWebTranscription["payload"]
): Promise<ServerTypes.ApiResponseStartWebTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "StartWebTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const listTranscriptionsAsync =
  async (): Promise<ServerTypes.ApiResponseListTranscriptions> => {
    try {
      const requestPayload: ServerTypes.ApiRequest = {
        action: "ListTranscriptions",
      };

      return await fetch(`/api/submit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: useUserSlice.getState().userToken,
        },
        body: JSON.stringify({ event: requestPayload }),
      }).then((res) => useAuthorized(res));
    } catch (e) {
      __DEV__ && console.log(e);
    }
  };

export const getTranscriptionAsync = async (
  params: ServerTypes.ApiRequestGetTranscription["payload"]
): Promise<ServerTypes.ApiResponseGetTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getTranscriptEditAsync = async (
  params: ServerTypes.ApiRequestGetTranscriptEdit["payload"]
): Promise<ServerTypes.ApiResponseGetTranscriptEdit> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetTranscriptEdit",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const editTranscriptAsync = async (
  params: ServerTypes.ApiRequestEditTranscript["payload"]
): Promise<ServerTypes.ApiResponseEditTranscript> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "EditTranscript",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const deleteTranscriptionAsync = async (
  params: ServerTypes.ApiRequestDeleteTranscription["payload"]
): Promise<ServerTypes.ApiResponseDeleteTranscription> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "DeleteTranscription",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getPaymentLinkAsync = async (
  params: ServerTypes.ApiRequestCheckout["payload"]
): Promise<ServerTypes.ApiResponseCheckout> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Checkout",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const sendInquiryAsync = async (
  params: ServerTypes.ApiRequestSendInquiry["payload"]
): Promise<ServerTypes.ApiResponseSendInquiry> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "SendInquiry",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const updateUserAsync = async (
  params: ServerTypes.ApiRequestUpdateUser["payload"]
): Promise<ServerTypes.ApiResponseUpdateUser> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "UpdateUser",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getWebUserStatusAsync =
  async (): Promise<ServerTypes.ApiResponseGetWebUserStatus> => {
    try {
      const requestPayload: ServerTypes.ApiRequest = {
        action: "GetWebUserStatus",
      };

      return await fetch(`/api/submit`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: useUserSlice.getState().userToken,
        },
        body: JSON.stringify({ event: requestPayload }),
      }).then((res) => useAuthorized(res));
    } catch (e) {
      __DEV__ && console.log(e);
    }
  };

export const authenticateAsync = async (
  params: ServerTypes.ApiRequestAuthenticate["payload"]
): Promise<ServerTypes.ApiResponseAuthenticate> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "Authenticate",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const addSpeakerLabelsAsync = async (
  params: ServerTypes.ApiRequestAddSpeakerLabels["payload"]
): Promise<ServerTypes.ApiResponseAddSpeakerLabels> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "AddSpeakerLabels",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const getWebUserAccessAsync = async (
  params: ServerTypes.ApiRequestGetWebUserAccess["payload"]
): Promise<ServerTypes.ApiResponseGetWebUserAccess> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "GetWebUserAccess",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const sendTranscriptionEmailAsync = async (
  params: ServerTypes.ApiRequestSendTranscriptionEmail["payload"]
): Promise<ServerTypes.ApiResponseSendTranscriptionEmail> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "SendTranscriptionEmail",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};

export const convertM4AAsync = async (
  params: ServerTypes.ApiRequestConvertM4A["payload"]
): Promise<ServerTypes.ApiResponseConvertM4A> => {
  try {
    const requestPayload: ServerTypes.ApiRequest = {
      action: "ConvertM4A",
      payload: params,
    };

    return await fetch(`/api/submit`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: useUserSlice.getState().userToken,
      },
      body: JSON.stringify({ event: requestPayload }),
    }).then((res) => useAuthorized(res));
  } catch (e) {
    __DEV__ && console.log(e);
  }
};
