import { DynamoDBClient } from "@aws-sdk/client-dynamodb";
import { DynamoDBDocument } from "@aws-sdk/lib-dynamodb";
import { Logger } from "./logger";

// DOCS: https://learn.microsoft.com/en-us/graph/api/user-sendmail?view=graph-rest-1.0&tabs=http
type Sender = {
  dailyLimit: number;
  usage: {
    [key: string]: number;
  };
  warmUpDailyLimit: number;
  email: string;
  updatedAt: string;
  createdAt: string;
};

interface Env {
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
}

export class ColdEmailClient {
  private readonly baseUrl: string;
  private dbClient: DynamoDBDocument;
  private logger: Logger;
  private readonly REGION = "us-east-2";
  private readonly TableName = "coldemail";
  private _accessToken: string | null = null;
  private readonly getAccessTokenUrl =
    "https://jbqrvnwlsmeljbmraxh5a3eosm0etykn.lambda-url.us-east-2.on.aws";

  constructor({ env, logger }: { env: Env; logger: Logger }) {
    this.logger = logger;

    const rawClient = new DynamoDBClient({
      region: this.REGION,
      credentials: env
        ? {
            accessKeyId: env.AWS_ACCESS_KEY_ID,
            secretAccessKey: env.AWS_SECRET_ACCESS_KEY,
          }
        : undefined,
    });

    this.dbClient = DynamoDBDocument.from(rawClient);

    this.baseUrl = `https://graph.microsoft.com/v1.0/users`;
  }

  private async getAccessToken(): Promise<string> {
    if (this._accessToken) return this._accessToken;

    const response = await fetch(this.getAccessTokenUrl).then(
      (res) => res.json() as Promise<{ accessToken: string }>
    );

    this._accessToken = response.accessToken;

    return this._accessToken;
  }

  private async listSenders(): Promise<Sender[]> {
    const response = await this.dbClient.query({
      TableName: this.TableName,
      KeyConditionExpression: "pk = :pk AND begins_with(sk, :sk)",
      ExpressionAttributeValues: {
        ":pk": "COLD_EMAIL",
        ":sk": "SENDER#",
      },
    });

    return response.Items as Sender[];
  }

  private async getSender(email: string): Promise<Sender | null> {
    const response = await this.dbClient.get({
      TableName: this.TableName,
      Key: { pk: "COLD_EMAIL", sk: `SENDER#${email.toLowerCase().trim()}` },
    });

    return response.Item as Sender | null;
  }

  private async updateSender(params: {
    email: string;
    updates: Partial<Sender>;
  }): Promise<Sender | null> {
    const previousSender = await this.getSender(params.email);

    if (!previousSender) return null;

    let item: Sender = {
      ...previousSender,
      ...params.updates,
      updatedAt: new Date().toISOString(),
    };

    await this.dbClient.put({
      TableName: this.TableName,
      Item: {
        pk: "COLD_EMAIL",
        sk: `SENDER#${params.email.toLowerCase().trim()}`,
        ...item,
      },
    });

    return item;
  }

  private async pickSender({
    isWarmUp = false,
  }: {
    isWarmUp?: boolean;
  }): Promise<Sender | null> {
    const senders = await this.listSenders();

    // Filter senders based on daily usage limits
    const eligibleSenders = senders.filter((sender) => {
      const dailyUsage =
        sender.usage[new Date().toISOString().split("T")[0]] || 0;
      const limit = isWarmUp ? sender.warmUpDailyLimit : sender.dailyLimit;

      return dailyUsage < limit;
    });

    if (eligibleSenders.length === 0) return null;

    // Pick a random sender from eligible ones
    const randomIndex = Math.floor(Math.random() * eligibleSenders.length);
    return eligibleSenders[randomIndex];
  }

  private async incrementSenderUsage(sender: Sender): Promise<Sender> {
    const updatedSender = await this.updateSender({
      email: sender.email,
      updates: {
        usage: {
          [new Date().toISOString().split("T")[0]]:
            ((sender.usage &&
              sender.usage[new Date().toISOString().split("T")[0]]) ||
              0) + 1,
        },
      },
    });

    return updatedSender;
  }

  public async sendEmail({
    isWarmUp,
    recipient,
    subject,
    emailBody,
  }: {
    isWarmUp: boolean;
    recipient: string;
    subject: string;
    emailBody: string;
  }): Promise<{
    success: boolean;
    fromEmail?: string;
    error?: "NO_SENDER_AVAILABLE" | string;
  }> {
    const [accessToken, sender] = await Promise.all([
      this.getAccessToken(),
      this.pickSender({ isWarmUp }),
    ]);

    if (!sender) {
      this.logger.error(
        `[Cold Email] - No sender available | warm up: ${isWarmUp}`,
        { isWarmUp }
      );

      return { success: false, error: "NO_SENDER_AVAILABLE" };
    }

    try {
      const emailConfig = {
        Message: {
          Subject: subject,
          Body: {
            ContentType: "HTML",
            Content: emailBody.replaceAll("__SENDER_EMAIL__", sender.email),
          },
          ToRecipients: [{ EmailAddress: { Address: recipient } }],
          from: { emailAddress: { address: sender.email } },
        },
        SaveToSentItems: "true",
      };

      const response = await fetch(`${this.baseUrl}/${sender.email}/sendMail`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailConfig),
      });

      this.logger.info(
        `[Cold Email] - Sent email to ${recipient} with sender ${sender.email} | warm up: ${isWarmUp}`,
        { sender, recipient, isWarmUp }
      );

      const updatedSender = await this.incrementSenderUsage(sender);

      this.logger.info(
        `[Cold Email] - Updated sender usage | email: ${sender.email}`,
        { updatedSender }
      );

      return { success: response.status === 202, fromEmail: sender.email };
    } catch (error) {
      this.logger.error(
        `[Cold Email] - Failed to send email to ${recipient} with sender ${sender?.email} | warm up: ${isWarmUp}`,
        { sender, recipient, isWarmUp }
      );

      return { success: false, error: String(error) };
    }
  }
}
