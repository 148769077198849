export const cipher = (jsonData: { [key: string]: any }): string => {
  const hexBuff = Buffer.from(JSON.stringify(jsonData));
  const hexData = hexBuff.toString("hex");

  const base64Buff = Buffer.from(hexData);
  return base64Buff.toString("base64");
};

export const decipher = (data: string): { [key: string]: any } => {
  const base64Buff = Buffer.from(data, "base64");
  const base64Data = base64Buff.toString("utf-8");

  const hexBuff = Buffer.from(base64Data, "hex");
  const hexData = hexBuff.toString("utf-8");

  return JSON.parse(hexData);
};
