import {
  View as RNWebView,
  TextInput as RNWebTextInput,
  Text as RNWebText,
  TouchableOpacity as RNWebTouchableOpacity,
  Image as RNWebImage,
  ScrollView as RNWebScrollView,
  StyleSheet as RNWebStyleSheet,
  Pressable as RNWebPressable,
  ActivityIndicator as RNWebActivityIndicator,
} from "react-native-web";

import {
  View as RNView,
  ActivityIndicator as RNActivityIndicator,
  TextInput as RNTextInput,
  Text as RNText,
  Image as RNImage,
  ScrollView as RNScrollView,
  TouchableOpacity as RNTouchableOpacity,
  StyleSheet as RNStyleSheet,
  Pressable as RNPressable,
  TextInputProps,
  ViewProps,
  ImageProps,
  TextStyle as RNTextStyle,
} from "react-native";

export const View: typeof RNView = RNWebView;
export const TextInput: typeof RNTextInput = RNWebTextInput;
export const Text: typeof RNText = RNWebText;
export const TouchableOpacity: typeof RNTouchableOpacity =
  RNWebTouchableOpacity;

export const StyleSheet: typeof RNStyleSheet = RNWebStyleSheet;

export const Image: typeof RNImage = RNWebImage;
export const Pressable: typeof RNPressable = RNWebPressable;
export const ActivityIndicator: typeof RNActivityIndicator =
  RNWebActivityIndicator;

export const ScrollView: typeof RNScrollView = RNWebScrollView;

export type TextInputStyle = TextInputProps["style"] & {
  outlineStyle: "none";
  cursor: "pointer";
};

export type ViewStyle = ViewProps["style"];
export type ImageStyle = ImageProps["style"];

export type TextStyle = RNTextStyle;
