import { View, Text, Pressable, StyleSheet } from "@/ui";
import * as fbq from "../fetchData/fpixel";
import { useUserSlice } from "@/models/useUserSlice";
import * as serverClient from "@/models/serverClient";
import { datadogLogs } from "@datadog/browser-logs";
import { useTranslation } from "react-i18next";
import Router, { useRouter } from "next/router";
import { PurchasePlan, UserAccess } from "@/api-lib";
import { useEffect, useState } from "react";
import { Colors } from "@/constants";
import Icon from "./Icon";
import ModalContainer from "./ModalContainer";
import { useUISlice } from "@/models/useUISlice";
import PrimaryButton from "./PrimaryButton";
import { useAuth } from "@/models/auth";

const Paywall = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const [selectedPlan, setSelectedPlan] = useState<PurchasePlan>("5_HOURS");
  const alertWidgetVisible = useUISlice((state) => state.alertWidgetVisible);
  const { goToDashboard } = useAuth();

  useEffect(() => {
    const slug = router.query.checkout_success;

    if (slug === "true") {
      fbq.event("Purchase", { currency: "USD", value: 4.99 });

      goToDashboard();
    }
  }, [router.query]);

  const _checkout = async (plan: PurchasePlan) => {
    const pathnames = new URL(window.location.href).pathname.split("/");

    let transcriptionId = pathnames.pop();

    const transcription = useUserSlice
      .getState()
      .transcriptions.find((t) => t.id === transcriptionId);

    if (transcription && transcription.isFullTranscript) {
      transcriptionId = undefined;
    }

    const response = await serverClient.getPaymentLinkAsync({
      baseAppUrl: window.location.href,
      purchasePlan: plan,
      transcriptionId,
    });

    if (response && response.data) {
      datadogLogs.logger.info("Funnel 9: Checkout", {
        deviceId: useUserSlice.getState().deviceId,
        purchasePlan: plan,
      });

      Router.push(response.data.url);
    }
  };

  if (alertWidgetVisible !== "paywall") return null;

  return (
    <ModalContainer
      title={t("paywall.title")}
      loading={false}
      subtitle={t("paywall.subtitle")}
      onDismiss={() => {
        useUISlice.getState().setAlertWidgetVisible(undefined);
      }}
      shouldPersistBackdropClick={true}
      primaryButtonText={t("paywall.upgrade")}
      hideCancel={true}
      modalWidth={360}
    >
      <View style={{ height: 16 }} />

      <Paywall.Item
        title={t("paywall.fiveHours")}
        description={t("paywall.priceSubtitle", {
          price: Paywall.getPrice("5_HOURS"),
        })}
        onPress={() => setSelectedPlan("5_HOURS")}
        isSelected={selectedPlan === "5_HOURS"}
      />

      <View style={{ height: 16 }} />

      <Paywall.Item
        title={t("paywall.fiftyHours")}
        description={t("paywall.priceSubtitle", {
          price: Paywall.getPrice("50_HOURS"),
        })}
        onPress={() => setSelectedPlan("50_HOURS")}
        isSelected={selectedPlan === "50_HOURS"}
      />

      <View style={{ height: 16 }} />

      <Paywall.Item
        title={t("paywall.hundredHours")}
        description={t("paywall.priceSubtitle", {
          price: Paywall.getPrice("100_HOURS"),
        })}
        onPress={() => setSelectedPlan("100_HOURS")}
        isSelected={selectedPlan === "100_HOURS"}
      />

      <View style={{ height: 24 }} />

      <PrimaryButton
        text={t("paywall.pay", {
          price: Paywall.getPrice(selectedPlan),
        })}
        height={56}
        textStyle={{ fontSize: 18 }}
        onPress={() => _checkout(selectedPlan)}
      />

      <View style={{ marginBottom: -24 }} />
    </ModalContainer>
  );
};

Paywall.Item = ({
  title,
  description,
  onPress,
  isSelected,
}: {
  title: string;
  description: string;
  onPress: () => void;
  isSelected: boolean;
}) => {
  return (
    <Pressable
      onPress={onPress}
      style={{
        ...s.planContainer,
        borderColor: isSelected ? Colors.accent : Colors.gray4,
      }}
    >
      <View style={{}}>
        <Text style={s.planTitle}>{title}</Text>
        <Text style={s.planDescription}>{description}</Text>
      </View>

      <View
        style={{
          ...s.checkbox,
          backgroundColor: isSelected ? Colors.accent : Colors.white,
          borderColor: isSelected ? Colors.accent : Colors.gray4,
        }}
      >
        <Icon icon="tick" style={s.checkboxIcon} />
      </View>
    </Pressable>
  );
};

const s = StyleSheet.create({
  checkbox: {
    width: 18,
    height: 18,
    borderWidth: 1.5,
    borderRadius: 4,
    alignItems: "center",
    justifyContent: "center",
    borderColor: Colors.subtitle,
  },
  checkboxIcon: {
    tintColor: Colors.white,
    width: 12,
    height: 12,
  },
  planContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    borderWidth: 1.5,
    borderRadius: 6,
    padding: 12,
  },
  planTitle: {
    fontWeight: "600",
    fontSize: 18,
    lineHeight: 24,
    color: Colors.text,
  },
  planPrice: {
    fontSize: 14,
    color: Colors.text,
    lineHeight: 22,
  },
  planDescription: {
    fontSize: 14,
    color: Colors.subtitle,
    lineHeight: 22,
  },
});

Paywall.getPrice = (plan: PurchasePlan): string => {
  const prices: { [key in PurchasePlan]: string } = {
    "5_HOURS": "$4.99",
    "50_HOURS": "$49.99",
    "100_HOURS": "$99.99",
  };

  let price = prices[plan];

  return price;
};

Paywall.showPaywall = () => {
  useUISlice.getState().setAlertWidgetVisible("paywall");
};

Paywall.getUserAccess = async (
  durationInSeconds?: number
): Promise<UserAccess> => {
  const userAccessResponse = await serverClient.getWebUserAccessAsync({
    durationInSeconds,
  });

  if (!userAccessResponse || !userAccessResponse.data) {
    return {
      canTranscribe: false,
      canShowSpeakerLabels: false,
      canShowDashboard: false,
      remainingTimeInSeconds: 0,
    };
  }

  return userAccessResponse.data;
};

export default Paywall;
