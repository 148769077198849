import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";
import {
  DbClient,
  Logger,
  ColdEmailClient,
  Transcript,
  Transcription,
  User,
} from "@/api-lib";
import { LanguageCode } from "@/constants";

interface Env {
  AWS_ACCESS_KEY_ID: string;
  AWS_SECRET_ACCESS_KEY: string;
  ZERO_BOUNCE_SECRET_KEY: string;
}

type SubjectId =
  | "shareFeedback"
  | "sendTranscriptionCompletedEmail"
  | "sendOneTimeCode";

export class SesClient {
  private client: SESClient;
  private region = "eu-west-1";
  private fromAddress = `"1Transcribe" <notifications@1transcribe.com>`;
  private zeroBounceBaseUrl: string;
  private coldEmailClient: ColdEmailClient;
  private logger: Logger;
  private dbClient: DbClient;

  constructor(env: Env, logger: Logger, dbClient: DbClient) {
    this.logger = logger;
    this.client = new SESClient({
      region: this.region,
      credentials: {
        accessKeyId: env.AWS_ACCESS_KEY_ID,
        secretAccessKey: env.AWS_SECRET_ACCESS_KEY,
      },
    });
    this.zeroBounceBaseUrl = `https://api.zerobounce.net/v2/validate?api_key=${env.ZERO_BOUNCE_SECRET_KEY}`;
    this.dbClient = dbClient;
    this.coldEmailClient = new ColdEmailClient({ env, logger });
  }

  private async sendEmail(params: {
    toAddress: string;
    subject: string;
    body: string;
    subjectId: SubjectId;
  }): Promise<void> {
    if (!(await this.isValidEmail(params.toAddress))) {
      return this.logger.info(
        `sesClient: ${params.toAddress} is not valid, skipping email`,
        { toAddress: params.toAddress }
      );
    }

    const command = new SendEmailCommand({
      Destination: { ToAddresses: [params.toAddress] },
      Message: {
        Body: { Html: { Charset: "UTF-8", Data: params.body } },
        Subject: { Charset: "UTF-8", Data: params.subject },
      },
      Source: this.fromAddress,
    });

    try {
      if (params.subjectId !== "sendTranscriptionCompletedEmail") {
        await this.client.send(command);

        return this.logger.info(
          `sesClient: Email sent to ${params.toAddress} | subjectId ${params.subjectId}`,
          { subjectId: params.subjectId, sender: this.fromAddress }
        );
      }

      const response = await this.coldEmailClient.sendEmail({
        isWarmUp: true,
        recipient: params.toAddress,
        subject: params.subject,
        emailBody: params.body,
      });

      if (response.success) {
        return this.logger.info(
          `sesClient: Cold email sent to ${params.toAddress} | subjectId ${params.subjectId}`,
          { subjectId: params.subjectId, fromEmail: response.fromEmail }
        );
      }

      await this.client.send(
        new SendEmailCommand({
          Destination: { ToAddresses: [params.toAddress] },
          Message: {
            Body: {
              Html: {
                Charset: "UTF-8",
                Data: params.body.replaceAll(
                  "__SENDER_EMAIL__",
                  this.fromAddress
                ),
              },
            },
            Subject: { Charset: "UTF-8", Data: params.subject },
          },
          Source: this.fromAddress,
        })
      );

      this.logger.info(`sesClient: response from sendEmail`, {
        subjectId: params.subjectId,
        fromEmail: this.fromAddress,
        recipient: params.toAddress,
      });
    } catch (e) {
      this.logger.error(
        `sesClient: Failed to send email: ${params.subjectId} | email: ${params.toAddress}`,
        { error: e }
      );
      return e;
    }
  }

  async isValidEmail(email: string): Promise<boolean> {
    try {
      if (email.endsWith("@1transcribe.com")) return false;

      const verificationResult = await this.dbClient.getEmailValidation(email);

      if (verificationResult) {
        this.logger.info(
          `sesClient: ${email} is ${verificationResult.isValid ? "valid" : "invalid"}`,
          { verificationResult }
        );

        return verificationResult.isValid;
      }

      let zeroBounceResult = { status: "valid" };

      try {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        try {
          zeroBounceResult = await fetch(
            `${this.zeroBounceBaseUrl}&email=${email}`,
            { signal: controller.signal }
          ).then((res) => res.json());
        } finally {
          clearTimeout(timeoutId);
        }
      } catch (e) {
        this.logger.error(`sesClient: ZeroBounceError: ${e}`, { e });
      }

      this.logger.info("sesClient: ZeroBounceResult", { zeroBounceResult });

      const isValid = zeroBounceResult.status === "valid";

      await this.dbClient.createEmailValidation({
        email,
        isValid,
        status: zeroBounceResult.status,
        apiResponseJson: JSON.stringify(zeroBounceResult),
      });

      return isValid;
    } catch (e) {
      this.logger.error(`sesClient: ValidateEmailError: ${e}`, { e });

      return true;
    }
  }

  async shareFeedback(params: {
    feedback: string;
    userId: string;
    email: string;
  }) {
    const feedbackText = `
  <html>
  <body>
    <br>${params.feedback} <br><br>
    <br>email: ${params.email} <br>
    <br>userId: ${params.userId} <br>
  </body>
</html>
  `.trim();

    await this.sendEmail({
      toAddress: "sadeck@1transcribe.com",
      subject: `1Transcribe - Feedback from ${params.email}`,
      body: feedbackText,
      subjectId: "shareFeedback",
    });
  }

  private getTranscriptionCompletedEmail({
    language,
    fileName,
    transcriptUrl,
    transcript,
    country,
  }: {
    language: LanguageCode;
    fileName: string;
    transcriptUrl: string;
    transcript: string[];
    country: string;
  }) {
    const defaultEmail = {
      subject: `Your transcript ${fileName} is ready!`,
      body: `
      <html>
        <body>
          <p>Great news! 😊</p>
          <p>Your file <b>${fileName}</b> has been transcribed. Click here to <a href="${transcriptUrl}">View full transcript</a></p>

          <p><b>Your Transcript</b></p>

          ${transcript.map((text) => `<span>${text}</span><br/><br/>`).join("\n")}

          <p>Click here to view your full transcript <a href="${transcriptUrl}">View full transcript</a></p><br>

          <p>Thanks for using 1Transcribe!</p>
        </body>
      </html>
      `.trim(),
    };

    switch (language) {
      case "en":
        return defaultEmail;

      case "es":
        return {
          subject: `¡Tu transcripción ${fileName} está lista!`,
          body: `
          <html>
            <body>
              <p>¡Excelentes noticias! 😊</p>
              <p>Tu archivo <b>${fileName}</b> ha sido transcrito. Haz clic aquí para <a href="${transcriptUrl}">Ver transcripción completa</a></p>

              <p><b>Tu Transcripción</b></p>

          ${transcript.map((text) => `<span>${text}</span><br/><br/>`).join("\n")}

              <p>Haz clic aquí para ver tu transcripción completa <a href="${transcriptUrl}">Ver transcripción completa</a></p><br>

              <p>¡Gracias por usar 1Transcribe!</p>
            </body>
          </html>
          `.trim(),
        };

      default:
        return defaultEmail;
    }
  }

  async sendTranscriptionCompletedEmail(params: {
    user: User;
    transcription: Transcription;
    transcript: Transcript;
  }) {
    if (!params.user || !params.transcription) return;

    try {
      const locale = params.user.deviceLanguage.slice(0, 2) || "en";

      const { subject, body } = this.getTranscriptionCompletedEmail({
        country: params.user.country,
        language: locale as LanguageCode,
        fileName: params.transcription.fileName.split(".")[0],
        transcriptUrl: `https://1transcribe.com/${locale}/transcription/${params.transcription.id}?utm_source=email&utm_medium=transcription-completed&utm_sender=__SENDER_EMAIL__`,
        transcript: params.transcript.slice(0, 5).map((segment) => {
          const hours = Math.floor(segment.start / 3600);
          const minutes = Math.floor((segment.start % 3600) / 60);
          const seconds = Math.floor(segment.start % 60);
          const timeStr = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

          return `${timeStr} ${segment.text}`;
        }),
      });

      await this.sendEmail({
        toAddress: params.user.email,
        subject,
        body,
        subjectId: "sendTranscriptionCompletedEmail",
      });

      this.logger.info(
        `sesClient: Transcription completed email sent to ${params.user.email}`,
        { email: { subject, body } }
      );
    } catch (e) {
      this.logger.error(
        `sesClient: Failed to send Transcription completed email`,
        { e }
      );
    }
  }

  getOneTimeCodeEmail(params: { email: string; oneTimeCode: number }) {
    return {
      subject: "Your one-time code",
      body: `
      <html>
        <body>
          <p>Hey there!</p>
          <p>Your one-time code is <b>${params.oneTimeCode}</b></p>
          <p>Thanks for using 1Transcribe!</p>
        </body>
      </html>
      `.trim(),
    };
  }

  async sendOneTimeCode(params: { email: string; oneTimeCode: number }) {
    try {
      const { subject, body } = this.getOneTimeCodeEmail(params);

      await this.sendEmail({
        toAddress: params.email,
        subject,
        body,
        subjectId: "sendOneTimeCode",
      });

      this.logger.info(
        `sesClient: One-time code email sent to ${params.email}`,
        { email: { subject, body } }
      );
    } catch (e) {
      this.logger.error(`sesClient: Failed to send One-time code email`, { e });
    }
  }
}
