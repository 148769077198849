import { datadogLogs } from "@datadog/browser-logs";
import { TranscriptExportDocument } from "@/api-lib";
import { Document, Paragraph, TextRun, Packer } from "docx";
import { jsPDF } from "jspdf";
import { ArabicStringBase64 } from "@/fonts/arabic";
import { AVAILABLE_LOCALES } from "@/constants";

export const getLocalizedBasePath = () => {
  if (typeof window === "undefined") return "/en";

  const localeId = window.location.pathname.split("/")[1];

  if (AVAILABLE_LOCALES.includes(localeId)) {
    return `/${localeId}`;
  }

  return "/en";
};

export function getMobileOperatingSystem() {
  if (typeof window !== "object") return;

  var userAgent =
    navigator.userAgent || navigator.vendor || (window as any).opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return "iOS";
  }

  return "unknown";
}

export const getLangDir = (): "rtl" | "ltr" => {
  const rtlLanguages = ["ar", "he", "fa", "ur"];

  return rtlLanguages.includes(getLocale()) ? "rtl" : "ltr";
};

export const getLocale = () => {
  if (typeof window !== "object") return "en";

  const basePath = window.location.pathname;
  let locale = "en";

  const localeFromPath = basePath.split("/")[1];

  if (localeFromPath.length === 2) {
    locale = localeFromPath;
  }

  return locale;
};

export function formatDate(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
    hour12: false,
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleString("en-US", options).replace(" at ", ", ");
}

export function formatDuration(ms: number): string {
  const duration = Math.floor(ms / 1000);
  const hours = Math.floor(duration / 3600);
  const minutes = Math.floor((duration % 3600) / 60);
  const seconds = duration % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
}

export function convertToSubtitleTime(timeInMs: number): string {
  const milliseconds = timeInMs % 1000;
  const seconds = Math.floor((timeInMs / 1000) % 60);
  const minutes = Math.floor((timeInMs / (1000 * 60)) % 60);
  const hours = Math.floor((timeInMs / (1000 * 60 * 60)) % 24);

  const formattedMilliseconds = String(milliseconds).padStart(3, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedHours = String(hours).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds},${formattedMilliseconds}`;
}

export function convertTranscriptToString(
  items: { text: string; metadata?: string }[]
) {
  let formattedContent = "";

  for (let i = 0; i < items.length; i++) {
    const { metadata, text } = items[i];

    if (metadata) {
      formattedContent += metadata + "\n";
    }

    formattedContent += text.trim() + "\n\n";
  }

  return formattedContent;
}

export async function exportTranscript({
  fileName,
  items,
  extension,
  noDownload = false,
}: {
  extension: TranscriptExportDocument & ("txt" | "srt");
  fileName: string;
  items: { text: string; metadata?: string }[];
  noDownload?: boolean;
}): Promise<Blob | undefined> {
  try {
    const formattedContent = convertTranscriptToString(items);

    // Create a Blob with the content
    const blob = new Blob([formattedContent], { type: "text/plain" });

    if (noDownload) {
      return blob;
    }

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    await _downloadFile(`${fileName}.${extension}`, url);
  } catch (error) {
    datadogLogs.logger.error("Error creating the text file:", error);
  }
}

export const _downloadFile = async (fileName: string, url: string) => {
  // Create a temporary anchor element
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = fileName;

  // Append to the document body and trigger the download
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Clean up
  document.body.removeChild(downloadLink);
  URL.revokeObjectURL(url);
};

export async function exportTranscriptToDocx({
  fileName,
  items,
  noDownload = false,
}: {
  fileName: string;
  items: { text: string; metadata?: string }[];
  noDownload?: boolean;
}) {
  try {
    const childrenArray: Paragraph[] = [];

    items.forEach((item) => {
      const paragraph = new Paragraph({ children: [] });

      if (item.metadata) {
        const metadataRun = new TextRun(item.metadata);
        paragraph.addChildElement(metadataRun);
        paragraph.addChildElement(new TextRun({ text: "", break: 1 }));
      }

      const textRun = new TextRun(item.text.trim());
      paragraph.addChildElement(textRun);

      childrenArray.push(paragraph);
      item.metadata && childrenArray.push(new Paragraph({ text: "" }));
    });

    const doc = new Document({
      sections: [{ children: childrenArray }],
    });

    const buffer = await Packer.toBuffer(doc);

    // Create a Blob with the content
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });

    if (noDownload) {
      return blob;
    }

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    await _downloadFile(`${fileName}.docx`, url);
  } catch (error) {
    datadogLogs.logger.error("Error creating the DOCX file:", error);
  }
}

export async function exportTranscriptToPdf({
  fileName,
  items,
  noDownload = false,
}: {
  fileName: string;
  items: { text: string; metadata?: string }[];
  noDownload?: boolean;
}): Promise<Blob | undefined> {
  try {
    const doc = new jsPDF({
      format: "a4",
      unit: "mm",
    });
    doc.addFileToVFS("Amiri-Regular.ttf", ArabicStringBase64);
    doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
    doc.setFont("Amiri"); // set font
    let yOffset = 20; // Start with more top margin
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20; // Left and right margins
    const lineHeight = 5; // Reduced line height for tighter text

    items.forEach((item, index) => {
      const { metadata, text } = item;

      if (metadata) {
        if (yOffset > pageHeight - 30) {
          doc.addPage();
          yOffset = 20;
        }
        doc.setFontSize(10);
        doc.setTextColor(100); // Gray color for metadata
        doc.text(metadata, margin, yOffset);
        yOffset += lineHeight;
      }

      doc.setFontSize(12);
      doc.setTextColor(0); // Black color for main text
      const maxWidth = pageWidth - 2 * margin;
      const lines = doc.splitTextToSize(text.trim(), maxWidth);

      lines.forEach((line: string, lineIndex: number) => {
        if (yOffset > pageHeight - 30) {
          doc.addPage();
          yOffset = 20;
        }
        doc.text(line, margin, yOffset);
        yOffset += lineHeight;
      });

      // Add a small space only if it's not the last item
      if (index < items.length - 1) {
        yOffset += lineHeight * 0.5;
      }
    });

    if (noDownload) {
      return doc.output("blob");
    }

    doc.save(`${fileName}.pdf`);
  } catch (error) {
    datadogLogs.logger.error("Error creating the PDF file:", error);
  }
}
