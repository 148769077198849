import React, { useEffect, useRef, useState } from "react";
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ViewStyle,
} from "@/ui/index";
import { Colors, LanguageCode } from "@/constants";
import Icon from "@/ui/Icon";
import { audioLanguages } from "@/constants";

const PickerSelectLanguage = ({
  onChange,
  label,
  style = {},
  defaultCurrentIndex = 0,
  disabled = false,
  position = "top",
}: {
  defaultCurrentIndex?: number;
  onChange: (languageCode: LanguageCode) => void;
  label: string;
  style?: ViewStyle;
  position?: "top" | "bottom";
  disabled?: boolean;
}) => {
  const [visible, setVisible] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(defaultCurrentIndex);
  const [searchQuery, setSearchQuery] = useState("");
  const pickerRef = useRef(null);
  const languagesArray = audioLanguages;

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const _onItemPress = (el: (typeof languagesArray)[number]) => {
    const index = languagesArray.findIndex((item) => item.value === el.value);
    setCurrentIndex(index);
    onChange(el.value);
    setVisible(false);
    setSearchQuery("");
    setHoverIndex(-1);
  };

  const filteredLanguages = audioLanguages.filter((language) =>
    language.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <View style={{ flex: 1, zIndex: 10, ...(style as any) }} ref={pickerRef}>
      <View style={{ flexGrow: 1 }}>
        <Text style={s.label}>{label}</Text>

        <TouchableOpacity
          disabled={disabled}
          activeOpacity={0.8}
          onPress={() => setVisible(!visible)}
          style={s.selector}
        >
          <Text style={s.selectorText}>
            {audioLanguages[currentIndex].label}
          </Text>
          <Icon
            icon="caretDown"
            style={{ tintColor: Colors.gray6, width: 16, height: 16 }}
          />
        </TouchableOpacity>
      </View>

      {visible && (
        <ScrollView
          style={{
            ...s.scrollView,
            bottom: position === "top" ? 42 : undefined,
            top: position === "bottom" ? 64 : undefined,
          }}
        >
          <TextInput
            style={s.searchBar}
            autoFocus={true}
            placeholderTextColor={Colors.gray6}
            placeholder="Search language..."
            value={searchQuery}
            onChangeText={setSearchQuery}
          />
          {filteredLanguages.map((el, index) => (
            <Pressable
              onPress={() => _onItemPress(el)}
              onHoverIn={() => setHoverIndex(index)}
              onHoverOut={() => setHoverIndex(-1)}
              style={{
                ...s.scrollItem,
                backgroundColor:
                  hoverIndex === index ? Colors.hover : "transparent",
              }}
              key={`${el.value}_${index}`}
            >
              <Text style={{ fontSize: 14, color: Colors.text }}>
                {el.label}
              </Text>
            </Pressable>
          ))}
        </ScrollView>
      )}
    </View>
  );
};

const s = StyleSheet.create({
  selector: {
    flex: 1,
    minHeight: 40,
    height: 40,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderColor: Colors.borderColor,
    paddingHorizontal: 12,
  },
  selectorText: { fontSize: 14, color: Colors.text },
  label: {
    fontSize: 14,
    fontWeight: "400",
    color: Colors.text,
    marginBottom: 4,
  },
  scrollView: {
    position: "absolute",
    bottom: 42,
    left: 0,
    width: "100%",
    borderRadius: 8,
    backgroundColor: Colors.white,
    borderColor: Colors.borderColor,
    borderWidth: 1,
    zIndex: 10,
    maxHeight: 300,
  },
  scrollItem: {
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderColor,
    justifyContent: "center",
    paddingHorizontal: 8,
  },
  categoryLabel: {
    fontSize: 12,
    fontWeight: "400",
    color: Colors.gray6,
    paddingVertical: 8,
    paddingHorizontal: 8,
    backgroundColor: Colors.gray2,
  },
  searchBar: {
    padding: 8,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderColor,
    fontSize: 16,
    color: Colors.text,
    marginBottom: 4,
  },
});

export default PickerSelectLanguage;
