import {
  DeleteObjectCommand,
  PutObjectCommand,
  S3Client,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export class R2Client {
  private readonly bucketName = "1transcribe";
  private readonly accountId: string;
  private readonly accessKeyId: string;
  private readonly sliceAudioEndpoint: string =
    "https://mxaamhlj7bunj6ryuo7vqfpqsa0grqkk.lambda-url.us-east-2.on.aws";
  private readonly convertM4AEndpoint: string =
    "https://jconvtge6nnnfqg4wykz7g6hzy0stcnl.lambda-url.us-east-2.on.aws";
  private readonly secretAccessKey: string;
  private client: S3Client;

  constructor(params: {
    accountId: string;
    accessKeyId: string;
    secretAccessKey: string;
  }) {
    this.accountId = params.accountId;
    this.accessKeyId = params.accessKeyId;
    this.secretAccessKey = params.secretAccessKey;
    this.client = new S3Client({
      region: "auto",
      endpoint: `https://${this.accountId}.r2.cloudflarestorage.com`,
      credentials: {
        accessKeyId: this.accessKeyId,
        secretAccessKey: this.secretAccessKey,
      },
    });
  }

  async getUploadUrl(fileName: string): Promise<string> {
    return await getSignedUrl(
      this.client,
      new PutObjectCommand({ Bucket: this.bucketName, Key: fileName }),
      { expiresIn: 3600 }
    );
  }

  getDownloadUrl(params: {
    transcriptionId: string;
    fileExtension: string;
  }): string {
    return `https://files.1transcribe.com/${params.transcriptionId}.${params.fileExtension}`;
  }

  async sliceAudio(params: {
    transcriptionId: string;
    fileExtension: string;
    trimTimeInSeconds?: number;
  }): Promise<string> {
    let url = `${this.sliceAudioEndpoint}?fileUrl=${this.getDownloadUrl(params)}`;

    if (params.trimTimeInSeconds) {
      url += `&trimTimeInSeconds=${params.trimTimeInSeconds}`;
    }

    const slicedAudioResponse = await fetch(url)
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return null;
      });

    return (slicedAudioResponse as { url: string }).url;
  }

  async convertM4A(params: {
    fileUrl: string;
    toExtension: ".mp3" | ".wav";
  }): Promise<string> {
    let url = `${this.convertM4AEndpoint}?fileUrl=${params.fileUrl}&toExtension=${params.toExtension}`;

    const convertedAudioResponse = await fetch(url)
      .then((res) => res.json())
      .catch((e) => {
        console.error(e);
        return null;
      });

    return (convertedAudioResponse as { url: string }).url;
  }

  async uploadAudioTranscript(params: {
    transcriptionId: string;
    data: string;
    suffix?: string;
  }): Promise<string> {
    const objectKey = `${params.transcriptionId}${
      params.suffix ? `_${params.suffix}` : ""
    }.json`;

    await this.client.send(
      new PutObjectCommand({
        Bucket: this.bucketName,
        Key: objectKey,
        Body: JSON.stringify(params.data),
        ContentType: "application/json",
      })
    );

    return `https://files.1transcribe.com/${objectKey}`;
  }

  async deleteTranscription(
    transcriptionId: string,
    fileExtension: string
  ): Promise<void> {
    await Promise.all([
      this.client.send(
        new DeleteObjectCommand({
          Bucket: this.bucketName,
          Key: `${transcriptionId}.${fileExtension}`,
        })
      ),
      this.client.send(
        new DeleteObjectCommand({
          Bucket: this.bucketName,
          Key: `${transcriptionId}.json`,
        })
      ),
      this.client.send(
        new DeleteObjectCommand({
          Bucket: this.bucketName,
          Key: `converted/${transcriptionId}.mp3`,
        })
      ),
    ]);
  }
}
