import { useState, useMemo } from "react";
import { datadogLogs } from "@datadog/browser-logs";
import Uppy, { UppyFile } from "@uppy/core";
import { View } from "@/ui";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import { useUserSlice } from "@/models/useUserSlice";
import { audioLanguages } from "@/constants";
import AwsS3, { type AwsS3UploadParameters } from "@uppy/aws-s3";
import PickerSelectLanguage from "@/ui/PickerSelectLanguage";
import PrimaryButton from "@/ui/PrimaryButton";
import * as serverClient from "@/models/serverClient";
import { useTranslation } from "react-i18next";
import { useRouter } from "next/router";
import { getLocalizedBasePath } from "@/models/utils";
import { useAuth } from "@/models/auth";
import toast from "react-hot-toast";
import { Transcription } from "@/api-lib";
import Paywall from "./Paywall";

async function getFileDuration(file: File): Promise<number> {
  if ((file as any).duration) return (file as any).duration;

  const video = document.createElement("video");
  video.src = URL.createObjectURL(file);
  video.preload = "metadata";
  video.muted = true;
  video.autoplay = false;
  video.playsInline = true;

  return new Promise((resolve, reject) => {
    video.addEventListener("loadedmetadata", () => {
      resolve(video.duration);
    });
  });
}

async function getUploadParameters(
  file: UppyFile<any, any>,
  source: Transcription["source"]
) {
  const duration = await getFileDuration(file.data as File);

  const response = await serverClient.createTranscriptionAsync({
    durationInSeconds: Number(duration.toFixed(2)),
    fileId: file.id,
    source,
    isRecording: Boolean((file.data as any).duration),
    fileName: file.name.trim(),
    fileSizeMb: Number((file.size / 1024 / 1024).toFixed(2)),
    fileExtension: file.name.split(".").pop(),
  });

  if (!response || response.error) {
    datadogLogs.logger.error("Failed to create transcription", {
      error: response && response.error ? response.error : "unknown",
    });
    throw new Error("Failed to create transcription");
  }

  const object: AwsS3UploadParameters = {
    method: "PUT",
    url: response.data.uploadUrl,
    fields: {},
    headers: {
      "Content-Type": file.type ? file.type : "application/octet-stream",
    },
  };

  return object;
}

const AlertDashboardStartTranscription = () => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [dismissRequestCount, setDismissRequestCount] = useState(0);
  const { t } = useTranslation();
  const router = useRouter();
  const { isFreeUser, currentAuthUser } = useAuth();

  const currentFile = useUISlice((state) => state.alertStartTranscription);

  const transcriptionOptions = useUserSlice(
    (state) => state.transcriptionOptions
  );
  const currentLanguageIndex = audioLanguages.findIndex(
    (language) => language.value === transcriptionOptions.languageCode
  );

  const uppy = useMemo(() => {
    const uppy = new Uppy({
      autoProceed: true,
      restrictions: {
        // maxNumberOfFiles: 1,
        maxFileSize: 5 * 1024 * 1024 * 1024, // 5GB
      },
      onBeforeFileAdded: (file) => {
        return { ...file, id: new Date().getTime().toString() };
      },
    }).use(AwsS3, {
      id: "AwsS3",
      shouldUseMultipart: false,
      getUploadParameters: async (file) =>
        getUploadParameters(
          file,
          (await isFreeUser()) ? "web_guest" : "web_dashboard"
        ),
    });
    return uppy;
  }, []);

  uppy.on("complete", async (result) => {
    setIsUploading(false);

    if (!result.successful[0]) return;

    const fileId = result.successful[0].id;

    const processed = localStorage.getItem("processed");
    if (processed === fileId) return;

    localStorage.setItem("processed", fileId);

    const updateResponse = await serverClient.updateTranscriptionAsync({
      type: "OnStatusChange",
      updates: {
        fileId,
        deviceId: currentAuthUser().deviceId,
        status: "upload_completed",
      },
    });

    if (
      updateResponse &&
      updateResponse.data &&
      updateResponse.data.transcription
    ) {
      const updatedTranscription = updateResponse.data.transcription;

      datadogLogs.logger.info("Funnel 3: Upload completed", {
        deviceId: updatedTranscription.deviceId,
        language: updatedTranscription.languageCode,
        fileSize: updatedTranscription.fileSizeMb,
        duration: updatedTranscription.durationInSeconds,
      });

      useUserSlice
        .getState()
        .setTranscriptions([
          ...useUserSlice.getState().transcriptions,
          updatedTranscription,
        ]);

      useUISlice.getState().setAlertStartTranscription(undefined);
      setUploadProgress(0);

      const userAccess = await Paywall.getUserAccess(
        updateResponse.data.transcription.durationInSeconds
      );

      if (!userAccess.canTranscribe) {
        return Paywall.showPaywall();
      }

      router.push(
        `${getLocalizedBasePath()}/transcription/${updatedTranscription.id}`
      );
    }
  });

  uppy.on("upload-progress", (file, progress) => {
    setUploadProgress(
      Math.round((progress.bytesUploaded / progress.bytesTotal) * 100)
    );
  });

  uppy.on("error", (error) => {
    setIsUploading(false);

    datadogLogs.logger.error("UppyError: upload failed", {
      deviceId: currentAuthUser().deviceId,
      error,
    });
  });

  uppy.on("upload-error", (file, error, response) => {
    setIsUploading(false);

    datadogLogs.logger.error("UppyUploadError: upload failed", {
      deviceId: currentAuthUser().deviceId,
      status: response ? response?.status : "unknown",
      error,
      response,
    });
    uppy.retryAll();
  });

  const _onPressTranscribe = () => {
    if (currentFile instanceof File) {
      uppy.addFile(currentFile);
      setIsUploading(true);
    }
  };

  const _onDismiss = () => {
    if (isUploading && dismissRequestCount === 0) {
      setDismissRequestCount(dismissRequestCount + 1);

      return toast.loading(
        t("alertDashboardStartTranscription.uploadInProgress"),
        { duration: 3000 }
      );
    }

    setUploadProgress(0);
    useUISlice.getState().setAlertStartTranscription(undefined);
    uppy.removeFiles(uppy.getFiles().map((file) => file.id));
  };

  if (!currentFile) return null;

  const isDisabled = uploadProgress > 0;
  const fileName =
    currentFile instanceof File ? currentFile.name : currentFile.fileName;

  return (
    <ModalContainer
      shouldPersistBackdropClick
      title={fileName}
      onDismiss={_onDismiss}
      hideCancel
      subtitle={t("alertDashboardStartTranscription.subtitle", { fileName })}
      modalMaxWidth={360}
      modalWidth={360}
    >
      <View style={{ width: "100%" }}>
        <View style={{ zIndex: 0, width: "100%", marginTop: 16 }}>
          <PickerSelectLanguage
            disabled={isDisabled}
            onChange={(languageCode) => {
              useUserSlice.getState().setTranscriptionOptions({
                ...transcriptionOptions,
                languageCode,
              });
            }}
            label={t("alertDashboardStartTranscription.spokenLanguage")}
            defaultCurrentIndex={currentLanguageIndex}
          />
        </View>

        <View style={{ marginBottom: 24 }} />

        <PrimaryButton
          height={48}
          textStyle={{ fontSize: 18 }}
          loading={isUploading && uploadProgress === 0}
          disabled={isDisabled}
          text={
            uploadProgress > 0
              ? t("alertDashboardStartTranscription.uploading", {
                  progress: uploadProgress,
                })
              : t("alertDashboardStartTranscription.startTranscription")
          }
          onPress={_onPressTranscribe}
        />
        <View style={{ marginBottom: -32 }} />
      </View>
    </ModalContainer>
  );
};

export default AlertDashboardStartTranscription;
