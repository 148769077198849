import React from "react";
import { View, Text } from "@/ui/index";
import { useUISlice } from "@/models/useUISlice";
import ModalContainer from "@/ui/ModalContainer";
import Checkbox from "./Checkbox";
import PrimaryButton from "./PrimaryButton";
import { useTranslation } from "react-i18next";
import RadioButton from "./RadioButton";
import { Colors } from "@/constants/Colors";
import { TranscriptExportDocument } from "@/api-lib";

const AlertTranscriptOptions = () => {
  const transcriptOptions = useUISlice((state) => state.transcriptOptions);
  const [exportType, setExportType] =
    React.useState<TranscriptExportDocument>("docx");

  const [withTimestamps, setWithTimestamps] = React.useState(false);
  const [withSpeakers, setWithSpeakers] = React.useState(false);
  const [withMonologue, setWithMonologue] = React.useState(false);
  const { t } = useTranslation();

  if (!transcriptOptions) return null;

  const _onDismiss = () => {
    setWithTimestamps(false);
    setWithSpeakers(false);
    setWithMonologue(false);
    useUISlice.getState().setTranscriptOptions(undefined);
  };

  return (
    <ModalContainer
      title={t("alertTranscriptOptions.title")}
      onDismiss={_onDismiss}
      hideCancel
      modalWidth={360}
    >
      <View style={{ height: 16 }} />
      <Text style={{ fontSize: 12, fontWeight: "300", color: Colors.gray6 }}>
        {t("alertTranscriptOptions.exportFormat")}
      </Text>

      <RadioButton
        text={`${t("alertTranscriptOptions.txt")}`}
        isSelected={exportType === "txt"}
        onPress={() => setExportType("txt")}
      />
      <RadioButton
        text={`${t("alertTranscriptOptions.docx")}`}
        isSelected={exportType === "docx"}
        onPress={() => setExportType("docx")}
      />
      <RadioButton
        text={`${t("alertTranscriptOptions.pdf")}`}
        isSelected={exportType === "pdf"}
        onPress={() => setExportType("pdf")}
      />
      <RadioButton
        text={`${t("alertTranscriptOptions.srt")}`}
        isSelected={exportType === "srt"}
        onPress={() => setExportType("srt")}
      />

      <Text
        style={{
          fontSize: 12,
          marginTop: 24,
          fontWeight: "300",
          color: Colors.gray6,
        }}
      >
        {t("alertTranscriptOptions.outputOptions")}
      </Text>

      <View style={{ height: 16 }} />
      <Checkbox
        isRight
        title={t("alertTranscriptOptions.includeTimestamps")}
        containerStyle={{ marginBottom: 24 }}
        value={withTimestamps}
        onCheckboxChange={(value) => {
          setWithTimestamps(value);
          setWithMonologue(false);
        }}
      />

      {transcriptOptions.hasSpeakers && (
        <>
          <Checkbox
            isRight
            title={t("alertTranscriptOptions.includeSpeakers")}
            containerStyle={{ marginBottom: 24 }}
            value={withSpeakers}
            onCheckboxChange={(value) => {
              setWithSpeakers(value);
              value && setWithMonologue(false);
            }}
          />
        </>
      )}

      <Checkbox
        isRight
        title={t("alertTranscriptOptions.exportAsMonologue")}
        containerStyle={{ marginBottom: 32 }}
        value={withMonologue}
        onCheckboxChange={(value) => {
          setWithMonologue(value);

          if (value) {
            setWithSpeakers(false);
            setWithTimestamps(false);
          }
        }}
      />

      <PrimaryButton
        text={t("alertTranscriptOptions.exportButton")}
        onPress={() => {
          transcriptOptions.onExport({
            exportType,
            withTimestamps,
            withSpeakers,
            withMonologue,
          });
          _onDismiss();
        }}
      />
      <View style={{ marginBottom: -24 }} />
    </ModalContainer>
  );
};

export default AlertTranscriptOptions;
