import React, { useEffect, useState } from "react";
import { View } from "@/ui/index";
import { useUISlice } from "@/models/useUISlice";
import Input from "@/ui/Input";
import ModalContainer from "@/ui/ModalContainer";

const AlertPrompt = () => {
  const prompt = useUISlice((state) => state.alertPrompt);
  const setPrompt = useUISlice((state) => state.setAlertPrompt);
  const [text, setText] = useState("");
  const width = useUISlice((state) => state.width);

  useEffect(() => {
    if (!prompt) return;

    setText(prompt.defaultValue || "");
  }, [prompt]);

  if (!prompt) return null;

  const _onPress = () => {
    prompt.button.onPress(text);
  };

  return (
    <ModalContainer
      title={prompt.title}
      subtitle={prompt.subtitle}
      onDismiss={() => setPrompt(undefined)}
      primaryButtonText={prompt.button.text}
      onPrimaryButtonPress={_onPress}
      hideDismissIcon={prompt.hideDismissIcon}
      hideCancel={prompt.hideCancel}
      shouldPersistBackdropClick={prompt.shouldPersistBackdropClick}
      loading={prompt.loading}
      destructive={prompt.button.destructive}
      modalWidth={prompt.multiLine ? Math.min(width - 32, 500) : 360}
    >
      <View style={{ height: 16 }} />
      <Input
        autoFocus
        disabled={prompt.loading}
        height={prompt.multiLine ? 220 : undefined}
        multiLine={prompt.multiLine}
        value={text}
        placeholder={prompt.placeholder}
        onTextChange={(text) => setText(text)}
      />
    </ModalContainer>
  );
};

export default AlertPrompt;
